import React, { useEffect } from "react";
import "./hero.css";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

export default function Hero() {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Allow animation to repeat
    });
  }, []);

  return (
    <>
      <section className="Hero_Section">
        <div className="mainDivision_HeroSection">
          <h1 data-aos="fade-up">
            All Types of Wood Working Machines are Available Here
          </h1>
          <p data-aos="fade-up">
            At Mach Mart, we have all kinds of wood working machines. We make it
            easy for you to find what you need, like table saws, jointers, and
            planers. Our machines are of good quality and work well. Whether you're
            a beginner or an expert, we have the right tools to help you with
            your wood working projects.
          </p>
          <div className="btnsDiv_Hero" data-aos="fade-up">
            <a href={"tel:8503886992"}>
              <button className="callNowBTN_Hero">
                <i className="fa-solid fa-phone fa-lg"></i> Call Now
              </button>
            </a>
            <a target="blank" href={"https://wa.me//918503886992"}>
              <button className="whatsAppNowBTN_Hero">
                <i className="fa-brands fa-whatsapp fa-lg"></i> What's App
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
