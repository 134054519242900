import React, { useEffect } from "react";
import Slider from "react-slick"; // Importing react-slick for carousel
import AOS from "aos"; // Importing AOS
import "aos/dist/aos.css"; // AOS styles
import "./clients.css"; // Custom CSS
import smpLogo from "../../Assets/logoMachMart.png";
import logo1 from "../../Assets/images2.jpg";
import logo2 from "../../Assets/images.logo3-removebg-preview.png";
import logo3 from "../../Assets/images.logo1-removebg-preview (1).png"
import logo4 from "../../Assets/images.logo1-removebg-preview (1).png";
import logo5 from "../../Assets/sklogo-removebg-preview.png"


export default function Clients() {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1500, // Duration of the animation in milliseconds
      once: true, // Animation happens only once when scrolled into view
    });
  }, []);

  // Carousel settings
  const settings = {
    infinite: true,
    speed: 3000, // Speed for carousel autoplay
    autoplay: true,
    autoplaySpeed: 0, // Continuous scroll effect
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false, // No arrows for infinite running effect
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Example client logos (Replace with actual logo paths)
  const clients = [
    { id: 1, logo: logo1, name: "Client 1" },
    { id: 2, logo: logo2, name: "Client 2" },
    { id: 3, logo: logo3, name: "Client 3" },
    { id: 4, logo: logo4, name: "Client 4" },
    { id: 5, logo: logo5, name: "Client 5" },
    { id: 6, logo: smpLogo, name: "Client 6" },
  ];

  return (
    <section className="mn-sc-clients">
      <div className="carousel-container">
        <h1 data-aos="fade-up">Our Clients</h1>
        <p data-aos="fade-up">
          Trusted by businesses worldwide, our clients include industry leaders
          who value quality and innovation.
        </p>
        <Slider {...settings}>
          {clients.map((client) => (
            <div
              key={client.id}
              className="client-slide"
              data-aos="fade-up" // AOS animation
            >
              <img
                src={client.logo}
                alt={`${client.name} Logo`}
                className="client-logo"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
