import React, { useEffect } from "react";
import "./hero.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import bgImage from "../../Images/homeHeroBGImage.jpg";
import logo from "../../Assets/logoMachMart.png";

export default function Hero() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  return (
    <>
      <section className="mn-sc">
        <section className="mn-sc-abt-pg-hr">
          {/* Left Section */}
          <div className="left-section">
            {/* Top 70% Image */}
            <div className="top-image">
              <img src={bgImage} alt="Sample" />
            </div>
            {/* Bottom 30% Content */}
            <div className="bottom-content" data-aos="fade-up">
              <div className="social-icons">
                {/* Social Media Icons */}
                <Link
                  target="blank"
                  to={"https://www.instagram.com/machmartjaipur/"}
                >
                  <i className="fa-brands fa-instagram fa-lg"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/machmartjaipur/"
                  target="blank"
                >
                  <i className="fa-brands fa-facebook fa-lg"></i>
                </Link>
                <Link to="https://wa.me/918503886992" target="blank">
                  <i className="fa-brands fa-whatsapp fa-lg"></i>
                </Link>
                <Link to="https://www.youtube.com/@machmart8698" target="blank">
                  <i className="fa-brands fa-youtube fa-lg"></i>
                </Link>
              </div>
              <h2 data-aos="fade-up">About Us</h2>
              <p data-aos="fade-up">
                Mach Mart is a leading manufacturer of high-quality woodworking
                machines, specializing in combined planer machines, planer
                machines, and band saw machines. With a commitment to precision
                and durability, we provide reliable machinery that enhances
                efficiency in woodworking industries. Our machines are designed
                to deliver superior performance, ensuring seamless operations
                for carpenters and industrial manufacturers.
              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className="right-section">
            {/* Top 30% - Logo */}
            <div className="logo">
              <img data-aos="fade-up" src={logo} alt="Logo" />
            </div>
            {/* Bottom 70% - Heading */}
            <div className="heading" data-aos="fade-up">
              <h1>Your Partner in Progress: Innovate, Deliver, Succeed</h1>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
