import React, { useEffect } from "react";
import "./location.css";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

export default function Location() {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1500, // Animation duration
      once: true, // Trigger animations only once
    });
  }, []);

  return (
    <>
      <section className="LocationSection">
        <div className="mainDivision_LocationSection">
          <div className="contentDiv_Address" data-aos="fade-up">
            <h3>
              <i className="fa-solid fa-location-dot"></i> Address
            </h3>
            <p>
              Mach Mart <br />
              Plot No. H726B, Road No. 9F2, Vishwakarma Industrial Area, Jaipur,
              Rajasthan {"(302013)"}
            </p>

            <h3 data-aos="fade-up">
              <i className="fa-solid fa-clock"></i> Working Hours
            </h3>
            <p data-aos="fade-up">Open Everyday {"(9AM to 6PM)"}</p>

            <h3 data-aos="fade-up">
              <i className="fa-solid fa-envelope"></i> Email
            </h3>
            <p data-aos="fade-up">
              <a href="mailto:sales@machmart.in">sales@machmart.in</a>{" "}
              {"(For Sales)"}
            </p>
            <p data-aos="fade-up">
              <a href="mailto:support.machmart.in">support.machmart.in</a>{" "}
              {"(For Support)"}
            </p>

            <h3 data-aos="fade-up">
              <i className="fa-solid fa-phone"></i> Mobile No.
            </h3>
            <p data-aos="fade-up">
              <a href="tel:8503886992">8503886992</a>,{" "}
              <a href="tel:7849912491">7849912491</a>
            </p>
          </div>

          <div className="mapDiv_Map" data-aos="fade-up">
            <iframe
              title="Mach Mart location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3555.3088307956014!2d75.78897830000001!3d26.9887788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db352a58ad9a7%3A0xd3f9b9c797a27ce3!2sMach%20Mart!5e0!3m2!1sen!2sin!4v1734952596607!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}
