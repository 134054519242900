import React, { useEffect, useState } from "react";
import "./FloatingButtons.css";
import { Link } from "react-router-dom";

export default function FloatingButtons() {
  const [showTooltip, setShowTooltip] = useState(false);
  const [animateCallButton, setAnimateCallButton] = useState(false);

  useEffect(() => {
    // Show the tooltip after 5 seconds
    const timer = setTimeout(() => {
      setShowTooltip(true);
      setAnimateCallButton(true);
    }, 10000);

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="floating-buttons">
      {/* WhatsApp Button */}
      <a
        href="https://wa.me/8503886992"
        target="_blank"
        rel="noopener noreferrer"
        className="floating-button whatsapp-button"
      >
        <i className="fab fa-whatsapp"></i>
      </a>

      {/* Call Now Button */}
      <a
        href="tel:+918503886992"
        className={`floating-button call-button ${animateCallButton ? "shake" : ""
          }`}
      >
        <i className="fas fa-phone-alt"></i>
      </a>
      {showTooltip && <div className="call-tooltip">Call us now</div>}

      {/* Social Media + YouTube */}
      <div className="floating-social-media">
        <div
          className="social-icons"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {/* Social Media Icons */}
          <Link target="blank" to={"https://www.instagram.com/machmartjaipur/"}>
            <i className="fa-brands fa-instagram fa-lg"></i>
          </Link>
          <Link to="https://www.instagram.com/machmartjaipur/" target="blank">
            <i className="fa-brands fa-facebook fa-lg"></i>
          </Link>
          <Link to="https://wa.me/918503886992" target="blank">
            <i className="fa-brands fa-whatsapp fa-lg"></i>
          </Link>
          <Link
            to="https://www.youtube.com/@machmart8698"
            target="blank"
          >
            <i className="fa-brands fa-youtube fa-lg"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
