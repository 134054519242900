import React, { useState, useEffect } from "react";
import "./topSellingMachinery.css";
import smp_1 from "../../Images/1.png";
import smp_2 from "../../Images/2.png";
import smp_3 from "../../Images/3.png";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles
import { Link } from "react-router-dom";

export default function TopSelling() {
  const [hoveredCard, setHoveredCard] = useState(null);

  const machineryData = [
    {
      id: 1,
      name: "Chain Mortiser",
      description:
        "Designed for creating accurate and clean mortises in wood. Perfect for furniture making and timber construction.",
      features: [
        "Precision cutting for accurate mortises",
        "Adjustable depth and angle settings",
        "Durable chain mechanism for long-term use",
        "Ergonomic design for user comfort",
        "High efficiency for professional applications",
      ],
      image: smp_1,
    },
    {
      id: 2,
      name: "Vertical Band Saw",
      description:
        "Ideal for cutting curves and intricate shapes in wood, metal, and other materials. A must-have for workshops and factories.",
      features: [
        "High cutting precision for complex shapes",
        "Powerful motor for continuous operation",
        "Tiltable table for angled cuts",
        "Safety features for operator protection",
        "Low vibration for smooth performance",
      ],
      image: smp_2,
    },
    {
      id: 3,
      name: "Thickness Planer Machine",
      description:
        "A robust woodworking machine engineered to achieve uniform thickness across wooden boards with precision and ease.",
      features: [
        "Uniform thicknessing for professional results",
        "Heavy-duty build for demanding tasks",
        "Adjustable cutting depth for versatility",
        "Smooth feed mechanism for consistent output",
        "Low maintenance design for hassle-free operation",
      ],
      image: smp_3,
    },
  ];

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animations happen once
    });
  }, []);

  return (
    <section className="ts-machinery-section">
      <div className="ts-machinery-container">
        {/* Header Section */}
        <div className="ts-header">
          <h1>Premium Wood working Machinery for Professionals</h1>
          <p>
            At Mach Mart, we pride ourselves on delivering top-tier wood working
            machines tailored to the needs of professionals and businesses
            alike. Whether you’re in search of a <b>Randa Machine, Band Saw
            Machine, Planer Machine, or Wood Cutter</b>, our products are designed
            to elevate your craftsmanship.
          </p>
        </div>

        {/* Cards Section */}
        <div className="ts-cards-grid">
          {machineryData.map((machinery, index) => (
            <div
              key={machinery.id}
              className="ts-card"
              onMouseEnter={() => setHoveredCard(machinery.id)}
              onMouseLeave={() => setHoveredCard(null)}
              data-aos="fade-up"
              data-aos-delay={index * 100} // Adding delay for staggered animations
            >
              {/* Card Image */}
              <div className="ts-card-image-wrapper">
                <img
                  src={machinery.image}
                  alt={machinery.name}
                  className="ts-card-image"
                />
                {hoveredCard === machinery.id && (
                  <div className="ts-card-overlay">
                    <h3>{machinery.name}</h3>
                    <p>{machinery.description}</p>
                    <ul>
                      {machinery.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* Call-to-Action Buttons */}
              <div className="ts-card-actions">
                <Link to={"/contact"}>
                  {" "}
                  <button className="ts-btn ts-enquiry-btn">
                    <i className="fas fa-envelope"></i> Enquiry Now
                  </button>
                </Link>
                <Link target="blank" to={"https://wa.me//918503886992"}>
                  {" "}
                  <button className="ts-btn ts-whatsapp-btn">
                    <i className="fab fa-whatsapp"></i> WhatsApp Now
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
