import React, { useEffect } from "react";
import "./history.css";
import AOS from "aos";
import "aos/dist/aos.css";

const data = [
  {
    year: "2020",
    text: "Mach Mart was founded, launching our first range of wood working machinery.",
  },
  {
    year: "2022",
    text: "Expanded product offerings, adding automation and efficiency to our machines.",
  },
  {
    year: "2023",
    text: "Integrated cutting-edge technology and eco-friendly features into our machinery.",
  },
  {
    year: "2024",
    text: "Strengthened partnerships, expanding our production and national presence.",
  },
  {
    year: "2025",
    text: "Became a trusted brand and started exporting internationally.",
  },
];

const Timeline = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration
      once: true, // Animation happens only once
    });
  }, []);

  return (
    <section className="history-section">
      <div className="container">
        {/* Main Heading */}
        <h1 className="history-title" data-aos="fade-up">
          Our History
        </h1>
        <p data-aos="fade-up">
          Mach Mart started as a small workshop and has grown into a trusted
          leader in wood working machinery, driven by innovation, quality, and
          customer satisfaction.
        </p>

        {/* Timeline */}
        <div className="timeline">
          {data.map((item, index) => (
            <div className="timeline-item" key={index} data-aos="fade-up">
              <div className="circle">{String(index + 1).padStart(2, "0")}</div>
              <div className="line"></div>
              <div className="content">
                <h3>{item.year}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Timeline;
