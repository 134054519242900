import React, { useState, useEffect } from "react";
import AOS from "aos"; // Importing AOS
import "aos/dist/aos.css"; // AOS styles
import "./featured.css";
import smp_1 from "../../Images/1.png";
import smp_2 from "../../Images/2.png";
import smp_3 from "../../Images/3.png";

export default function Featured() {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1500, // Animation duration
      once: true, // Allow animation to occur multiple times
    });
  }, []);

  // Define images and content for each tab
  const tabs = [
    {
      id: 1,
      image: smp_1,
      icon: <i className="fas fa-cogs"></i>, // FontAwesome icon for machinery
      heading: "High-Precision Machinery",
      paragraph:
        "Mach Mart offers a wide range of high-precision wood working machines, including CNC wood working machines, wood routers, table saws, and band saws. Our edge banding machines, wood planers, and drilling machines for wood ensure flawless cuts, smooth finishes, and superior accuracy, making them ideal for all wood working applications. Trust Mach Mart for reliable, durable, and efficient machinery that enhances productivity.",
    },
    {
      id: 2,
      image: smp_2,
      icon: <i className="fas fa-bolt"></i>, // FontAwesome icon for energy
      heading: "Energy-Efficient Solutions",
      paragraph:
        "Mach Mart provides energy-efficient wood working machines like CNC routers, wood planers, and table saws designed to minimize power consumption while maintaining high performance. Our edge banding machines and band saws offer cost-effective solutions, reducing energy usage and operational costs without compromising on quality or productivity.",
    },
    {
      id: 3,
      image: smp_3,
      icon: <i className="fas fa-shield-alt"></i>, // FontAwesome icon for reliability
      heading: "Durable and Reliable",
      paragraph:
        "Mach Mart’s durable and reliable wood working machines, including CNC routers, wood lathes, and panel saws, are built to withstand heavy use while delivering consistent performance. Our drilling machines for wood and edge banding machines ensure long-lasting reliability, making them ideal for high-demand wood working projects.",
    },
  ];

  return (
    <section className="mn-sc-ftrd" data-aos="fade-up">
      <div className="head-p-para" data-aos="fade-up">
        <h1>Features of Our Machinery</h1>
        <p>
          Our machinery is designed to deliver exceptional performance and
          efficiency, meeting the highest industry standards. Engineered with
          precision, it ensures reliability, durability, and superior
          productivity for diverse applications.
        </p>
      </div>
      <div className="featured-container" data-aos="fade-up">
        {/* Left Image Section */}
        <div className="featured-image-section" data-aos="fade-up">
          <img
            src={tabs.find((tab) => tab.id === activeTab)?.image}
            alt="Feature"
          />
        </div>

        {/* Right Tab Section */}
        <div className="featured-tabs-section">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`featured-tab ${
                activeTab === tab.id ? "featured-tab--active" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <div className="featured-tab__icon" data-aos="fade-up">
                {tab.icon}
              </div>
              <div className="featured-tab__heading" data-aos="fade-up">
                {tab.heading}
              </div>
              <div className="featured-tab__paragraph" data-aos="fade-up">
                {tab.paragraph}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
